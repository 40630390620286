import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner, MarkdownContentTitle, ComingSoon } from '../components/Section'
import PrimaryLinkButton from '../components/Button/PrimaryLinkButton'
import { Link } from "gatsby"
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import BreakpointDown from '../components/Media/BreakpointDown'


const GroceryTitle = styled.h1`

`
const GroceryRow = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  flex-wrap:wrap;
  justify-content:space-between;
  margin-top:20px;
`
const GroceryColumn = styled.div`
 flex:0 0 50%;
 width:50%;
 padding-left:15px;
 padding-right:15px;
 ${BreakpointDown.xl`
  flex:0 0 100%;
  width:100%; 
 `}
 .gatsby-image-wrapper{ width:100%;}
 &:first-child{
   padding-right:60px;
   ${BreakpointDown.xl`
      flex:0 0 100%;
      width:100%;
      padding-right:15px;
    `}
 }
 &:last-child{
   padding-top:40px;
   ${BreakpointDown.md`
     padding-top:0px;
   `}
 }
`


// GroceryIndustry
const SectionBoxShadow = styled(Section)`
  box-shadow: 0px 20px 85px #00000033;
  z-index:2;
`
const GroceryIndustryTitle = styled.h2`
  max-width: 768px;
  text-align:center;
  margin-left:auto;
  margin-right:auto;
`

const GroceryIndustryList = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-top:60px;
 border-bottom:1px solid #CCCCCC;
 padding-bottom:60px;
 &:last-child{border-bottom:0; padding-bottom:0;}
 ${BreakpointDown.xl`
   margin-top:30px;
   padding-bottom:30px;
 `}
 &:nth-child(odd){
   flex-direction:row-reverse;
   &:last-child{border-bottom:0; padding-bottom:0;}
   .industry-left{
  &:before{
    right:auto;
    margin-right:0;
    left: 0;
    top: 35px;
    margin-left: -60px;
  }
   }
   .industry-right{
    padding-right: 90px;
    padding-left:0;
    ${BreakpointDown.xl`
      padding-right: 0px;
    `}
   }
 }
`
const GroceryIndustryListLeft = styled.div`
flex:0 0 600px;
position:relative;
${BreakpointDown.xl`
 flex:0 0 100%;
`}
&:before{
  content:'';
  width:120px;
  height:4px;
  background:#000;
  position:absolute;
  right:0;
  top:35px;
  margin-right:-60px;
  z-index:1;
  ${BreakpointDown.xl`
     display:none;
  `}
}
`
const GroceryIndustryListRight = styled.div`
  flex:1;
  padding-left:90px;
  padding-top:20px;
  ${BreakpointDown.xl`
    padding-left: 0px;
  `}
    ${BreakpointDown.md`
     padding-top:10px;
  `}
`
const GroceryIndustryListRightTitle = styled.div`
${BreakpointDown.md`
  margin-bottom:0;
`}
`
const GroceryIndustryListRightContent = styled.div`
 p{
   font-style:normal;
 }
`
const TraingleShape = styled.div`
  width: 0;
  height: 0;
  display: block;
  border-top: 20px transparent solid;
  border-right: 20px transparent solid;
  border-left: 20px transparent solid;
  border-bottom: 20px #fff solid;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
`


function BackupSolutionsPage() {
  return (
    <Layout pageName="technology">
      <SEO
        title="ISP Brokerage Technology and Cellular Backup Solutions"
        description="Secure your business with cellular backup and get ISP brokerage solutions. WYN Technologies can help you to get the best price for internet services. Contact us now."/>
      <Section pt="174px" pb="80px" bgColor="#F8F8F8" xpt="120px" xpb="60px" mpt="90px" mpb="40px">
        <TraingleShape />
        <div className="container">
          <BreadCrumb>
            <Link to="/">Home</Link><Link to="/#">ISP Brokerage and Cellular Backup Solutions</Link>
          </BreadCrumb>
          <GroceryRow>
            <GroceryColumn>
              <GroceryTitle>ISP Brokerage and Cellular Backup Solutions</GroceryTitle>
              <p>ISP brokerage is an important, if not often overlooked, aspect of IT services. Having an intermediary between the consumer and larger service providers gives a clear advantage over direct sales by providing brokers the power to consult and research the best services and prices. ISP brokers know the landscape and can quickly find the best fit for you. Without one, you may end up with a service provider that charges you for an internet package but doesn’t quite ‘provide’ the value you’re hoping to receive. So, before getting stuck in a long-term contract with a large telecom company, take a few moments to find out what ISP brokers can do for you !</p>

            </GroceryColumn>
            <GroceryColumn>
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Brands We Trust"
                placeholder="blurred"
              />
            </GroceryColumn>
          </GroceryRow>
        </div>
      </Section>
      <SectionBoxShadow bgColor="#F8F8F8" pt="80px" pb="80px" xpt="60px" xpb="60px" mpt="40px" mpb="40px">
        <div className="container">
          <GroceryIndustryTitle className="h1">What We Provide with Our ISP Brokerage & Cellular Backup Services</GroceryIndustryTitle>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Personal Consultation & Custom Quote Options</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Every modern business needs a reliable ISP. Are you aware of all the solutions that are available to you in your area? <strong>WYN Technologies</strong> can help you find the best options! We’ll take the time to learn more about your business, and to listen to your goals and needs. We can come sit down with you to go over everything in person, and we can provide at least three custom quotes which are perfectly tailored to meet your individual business internet needs. We’re also happy to provide quotes for some reliable cellular backup solutions for your business too.</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Sales, Installation, & Service Orientation</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p><strong>WYN</strong> isn’t just a broker of ISP services or cellular backup solutions; we’re also a preferred provider of network hardware solutions from all the leading manufacturers and brands of commercial technology products. WYN is happy to install everything we sell, and our clients are consistently pleased with our careful attention to quality workmanship. And once we get everything installed, we won’t just leave you hanging. We’ll make sure you understand how everything works, and we’re glad to walk you through proper product orientation. We’re here to help you get what you need and show you how to use it!</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
          <GroceryIndustryList>
            <GroceryIndustryListLeft className="industry-left">
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </GroceryIndustryListLeft>
            <GroceryIndustryListRight className="industry-right">
              <GroceryIndustryListRightTitle className="h3">Ongoing Support When You Need It</GroceryIndustryListRightTitle>
              <GroceryIndustryListRightContent>
                <p>Ever had a business technology provider leave you scratching your head after they finished putting in your equipment and then left you to fend for yourself? Ain’t nobody got time for that, and that’s not how we treat you at <strong>WYN Technologies</strong>, either! We’ll be here for you down the road, and we’re pleased to offer a “service after the sale” contract to make sure your equipment keeps working well for years to come. And anytime you may have a problem or issue, we’re only a phone call, email, or message away. WYN has you covered!</p>
                {/* <Link to="/"><PrimaryLinkButton text="Learn More"></PrimaryLinkButton></Link> */}
              </GroceryIndustryListRightContent>
            </GroceryIndustryListRight>
          </GroceryIndustryList>
        </div>
      </SectionBoxShadow>
      <Section pt="100px" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8">
        <div className="container">
          <MarkdownContentWrapper>
            <MarkdownContentWrapperInner>
              {/* <MarkdownContentTitle>
                <h2 className="h1">Content Heading</h2>
              </MarkdownContentTitle>
              <ComingSoon textAlign="center">Coming Soon...</ComingSoon> */}
              <MarkdownContent>
                <h2>Why Working with an ISP Broker is Important</h2>
                <p>As mentioned previously, utilizing the skills of an ISP broker can be especially beneficial if you’re hoping to find the right service coverage at the right price. Most service providers offer broad packages that might not fit a customer’s particular needs. Additionally, representatives for these corporations might not be as forthcoming about service areas and product quality.</p>
              </MarkdownContent>
              <MarkdownContent>
                <h2>Why You Need Cellular Backup for Your Internet</h2>
                <p>Our lives are increasingly dictated by hardware and software that require constant internet connections to function correctly. Though many service providers are efficient about minimizing service downtime, some customers might need a backup to prevent data loss. And for those that live in areas with extreme weather or topography, this can be a necessity! </p>
                <p>This is where cellular internet backup comes in! Much like an electric generator for your home, having a cellular hotspot connection for your computer will come in handy if your internet service were to go down. And with many affordable hardware and package options, it’s a cost-effective solution to ensure constant connectivity.</p>
              </MarkdownContent>
              <MarkdownContent>
                <h2>Benefits of Fast Business Internet Service</h2>
                <p>Businesses are no different! A failure of home internet service can be irritating, but that same lapse of connectivity can be financially devastating for a business! Internet service gaps can cause loss of sales and customers for smaller businesses. But in other sectors, such as the medical field, maintaining a constant network connection for cloud-based software, file transfer, and videoconferencing is essential</p>

                <p>We all know how frustrating poor internet connections can be in the office! A fast network connection provides many benefits for businesses, such as improved staff productivity, sales, and even employee morale. Business internet services can also cut costs, and some ISP’s offer extra online services such as static IP addresses, customizable WiFi splash pages, domain names, and security features.</p>
              </MarkdownContent>
              <MarkdownContent>
                <h2>Choosing the Right Business Internet Plan for You</h2>
                <p>Picking the right internet service for your business can be frustrating. But prior to deciding, a company will want to consider several factors.</p>
                <ul>
                  <li>Connection speed needed</li>
                  <li>What your business will be doing online</li>
                  <li>How many employees or clients will be simultaneously using the service</li>
                  <li>Extra features</li>
                  <li>Local availability</li>
                </ul>
                <p>These factors can greatly affect what services you’ll need and the speed that your business will require. As well, service providers are known to conflate their advertised speeds with what you’ll actually receive.</p>
              </MarkdownContent>
              <MarkdownContent>
                <h2>ISP Brokerage and Cellular Backup Solutions with WYN Technologies</h2>
                <p>Navigating the complex network of ISP’s and the packages they provide can be tricky even for a seasoned customer. Still, a company hoping to install or upgrade business-grade connectivity will want to consult with an ISP broker to discover the exact provider, package, and speeds they’ll need to conduct their business efficiently.</p>
               
                <p>WYN Technologies can help navigate all that noise! Our knowledgeable brokers are experts dedicated to providing network backup solutions for your company. Whether you need a new business internet connection, cellular security systems, or a cellular backup system in the event of an outage, give us a call today at <a href="tel:3368990555">(336) 899-0555</a>, and let us handle all the responsibility of researching your business internet and backup needs!</p>
              </MarkdownContent>
            </MarkdownContentWrapperInner>
          </MarkdownContentWrapper>
        </div>
      </Section>
    </Layout>
  )
}
export default BackupSolutionsPage
